(function() {
	'use strict';
	try {
		var
			waypoints = avm.require('avast.web.waypoints'),
			stickyBars = document.querySelectorAll('.sticky-bar'),
			buttons = document.querySelectorAll('.sticky-bar a'),
			messageBar = document.querySelector('.message-bar.sticky-message'),
			tables = document.querySelectorAll('.tables-common.sticky-header, .tables.tables--sticky'),
			waypoint = '.sticky-waypoint';

		// (un)set all sticky bar buttons to be tabable
		function changeTabindex(value) {
			buttons.forEach(function(button){
				button.setAttribute('tabindex', value);
			});
		}

		// (un)set class to sticky bar
		function makeSticky(bool) {
			stickyBars.forEach(function(bar){
				if (bool) {
					bar.classList.add('is-sticky');
					changeTabindex(0);
				} else {
					bar.classList.remove('is-sticky');
					changeTabindex(-1);
				}
			});
		}

		// stickiness logic
		function positionCheck() {
			var visibleTableHeader = false;

			// check if there is more than one table with sticky header and if in any of them is currently visible thus possibly colliding with other sticky elements
			if (tables && tables.length > 0) {
				tables.forEach(function(table){
					if (table.classList.contains('tables--sticky')) {
						var tableTop = table.querySelector('thead th');
					} else {
						var tableTop = table.querySelector('thead');
					}

					visibleTableHeader = !visibleTableHeader ? (waypoints(tableTop, 64, null, true) && waypoints(table)) : visibleTableHeader;
				});
			}


			//if sticky table header is present
			if (visibleTableHeader) {

				// hide both sticky bar and sticky message bar
				if (messageBar) {
					messageBar.classList.add('hidden');
				} else {
					makeSticky(false);
				}
			
			} else if (messageBar) {
				// if there is sticky message then disable sticky bar
				makeSticky(false);
				
				// make sure the sticky message bar is displayed once the sticky table is not in view anymore
				if (!visibleTableHeader) {
					messageBar.classList.remove('hidden');
				}
			} else if (waypoints(waypoint, null, null, true)) {
				// else make sticky bar sticky under given waypoint
				makeSticky(true);
			} else {
				// else hide sticky bar
				makeSticky(false);
			}
		}

		// initially disable all sticky bar buttons from tabbing scope
		changeTabindex(-1);
		

		// event listeners assignment only if waypoint class exists
		if (document.querySelector(waypoint) || messageBar) {
			window.addEventListener('scroll', function () {
				positionCheck();
			});

			window.addEventListener('resize', function () {
				positionCheck();
			});
		}
		

	} catch (error) {
		// singe data layer error tracking
		if (typeof sdl !== 'undefined') {
			console.error(error);
			sdl.push({
				system: {
					error: {
						type: error.name,
						message: error.message,
					}
				},
				event: 'system.error'
			});
		}
	}
})();
